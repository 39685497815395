































































































































































.editorWrapper {
  display: flex;
  .editor {
    flex: 1 0 50%;
    box-sizing: border-box;
  }
  .previewer {
    flex: 1 0 50%;
    box-sizing: border-box;
    min-height: 500px;
  }
}
